var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-content"},[_c('div',{staticClass:"info-container"},[_c('span',{staticClass:"title"},[_vm._v("Quem contratamos?")]),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("A Espacial busca profissionais comprometidos em alcançar o sucesso, convictos do seu\n       crescimento pessoal e que acompanhem a estratégia de expansão global da empresa.")]),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("Portanto, cria oportunidades para novos talentos que tenham interesse em se desenvolver\n       continuamente de acordo com as diretrizes e valores da empresa, como: trabalho em equipe, qualidade no atendimento\n       ao cliente e foco em resultados.")]),_vm._v(" "),_c('span',{staticClass:"subtitle secondary"},[_vm._v(_vm._s(_vm.$t('Entre em contato!')))]),_vm._v(" "),_c('div',{staticClass:"contacts"},[_c('div',[_c('SvgImage',{attrs:{"icon":"mail"}}),_vm._v(" "),_c('p',[_vm._v("alice@espacial.com.br")])],1),_vm._v(" "),_c('div',[_c('SvgImage',{attrs:{"icon":"phone"}}),_vm._v(" "),_c('p',[_vm._v("(11) 2602-6200")])],1),_vm._v(" "),_c('div',[_c('SvgImage',{attrs:{"icon":"whatsappwhite"}}),_vm._v(" "),_c('p',[_vm._v("(11) 2602-6200")])],1)]),_vm._v(" "),_c('NuxtImg',{staticClass:"logo",attrs:{"loading":"lazy","format":"webp","quality":"100","src":"/icons/logo_transparent.png"}})],1),_vm._v(" "),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"header"},[_c('NuxtImg',{attrs:{"loading":"lazy","format":"webp","quality":"100","src":"https://admin.espacial.com.br/media/wysiwyg/logomarca.png","alt":"Espacial Suprimentos"}})],1),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { _vm.$emit('submit', _vm.form) })}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label"},[_c('JetInput',{attrs:{"id":"name","type":'text',"autocomplete":"off","error-message":errors.length > 0 ? _vm.$t(errors[0]).toString() : '',"label":_vm.$t('Name').toString()},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label"},[_c('JetInput',{attrs:{"id":"email","type":'text',"autocomplete":"off","error-message":errors.length > 0 ? _vm.$t(errors[0]).toString() : '',"label":_vm.$t('E-mail').toString()},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label"},[_c('JetInput',{attrs:{"id":"phone","type":'text',"type-validation":"phone","autocomplete":"off","error-message":errors.length > 0 ? _vm.$t(errors[0]).toString() : '',"label":_vm.$t('Phone number').toString()},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"}},[_c('div',{staticClass:"label form__select"},[_c('label',[_vm._v(_vm._s(_vm.$t("Documents")))]),_vm._v(" "),_c('input',{attrs:{"type":"file","multiple":"","accept":"application/pdf"},on:{"change":function($event){return _vm.handleFileUpload($event)}}})])]),_vm._v(" "),_c('CloudFlareCaptcha',{on:{"result":function (result) { return _vm.setButtonDisabled(result); }}}),_vm._v(" "),_c('JetUiButton',{attrs:{"disabled":_vm.formDisabled},on:{"click":function($event){$event.preventDefault();return _vm.sendForm(_vm.form)}}},[_vm._v("\n           "+_vm._s(_vm.$t('Send'))+"\n         ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }