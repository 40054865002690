




































































import {
  defineComponent, ref, useContext
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import SvgImage from '~/components/General/SvgImage.vue';
import CloudFlareCaptcha from '~/components/JetUi/atoms/Captcha/CloudFlareCaptcha.vue';
import JetInput from '~/components/JetUi/atoms/Input/JetInput.vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import { useFile, useUiNotification } from '~/composables';
import EmailTemplate from '~/static/emailTemplate/emailTemplate';
import { WorkWithUsFields } from './composables/types';

// Extend vee-validate rules
extend('email', {
  ...email,
  message: 'Email inválido',
});

extend('required', {
  ...required,
  message: 'Este campo é obrigatório',
});

export default defineComponent({
  name: 'WorkWithUsForm',
  components: {
    SfButton,
    ValidationProvider,
    ValidationObserver,
    JetUiButton,
    JetInput,
    CloudFlareCaptcha,
    SvgImage
  },
  setup() {
    const formDisabled = ref(true);
    const { app: { i18n } } = useContext();
    const { send: sendNotification } = useUiNotification();
    const form = ref<WorkWithUsFields>({ email: '', name: '', phone: '', documents: [] });
    const { filesToBase64 } = useFile();

    const setButtonDisabled = (result: boolean) => {
      formDisabled.value = result
    }

    const sendForm = async (form: WorkWithUsFields) => {
      try {
        const apiUrl = '/email/send';
        setButtonDisabled(true);
        const attachments = await filesToBase64(form.documents);
        await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subject: "Trabalhe conosco",
            content:
              EmailTemplate(`
              <b>Mensagem enviada por:</b> ${form.name}<br>
              <b>Telefone:</b> ${form.phone}<br>
              <b>E-mail:</b> ${form.email}<br><br>
              Segue em anexo os documentos<br><br>
              <p>Mensagem enviada pelo e-commerce: https://www.espacial.com.br/</p>`),
            attachments: attachments
          }),
        })
        setButtonDisabled(false);
        sendNotification({
          id: Symbol('email_sent'),
          message: i18n.t('E-mail has been sent') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'E-mail sent',
        });
      } catch (error) {
        console.error('Error sending data:', error);
      }
    };

    const handleFileUpload = (event: Event): void => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        form.value.documents = Array.from(target.files)
      }
    };

    return {
      form,
      formDisabled,
      sendForm,
      setButtonDisabled,
      handleFileUpload
    };
  },
});
