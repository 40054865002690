











import {
  defineComponent, ref, onMounted,
} from '@nuxtjs/composition-api';
import WorkWithUsForm from '~/components/JetUi/organisms/WorkWithUs/WorkWithUsForm.vue';

export default defineComponent({
  name: 'Trabalhe conosco',
  components: {
    WorkWithUsForm,
  },
  setup() {
    const loading = ref(true);
    onMounted(() => loading.value = false);

    return {
      loading,
    };
  },
  head() {
    return {
      title: this.$t('Work with us').toString(),
    };
  },
});
